// Import Swiper React components
// Import Swiper styles
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import './PuxCarousel.scss'

import React, { FunctionComponent } from 'react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay, Navigation])

export interface PuxCarouselType {
  contentItemId?: string
  slides: JSX.Element[]
  slidesPerView?: number
  externalNavigation?: boolean
  breakpoints?: any
}

const rootClass = `carousel`

const DEFAULT_SWIPER_BREAKPOINTS = {
  640: {
    autoHeight: false,
  },
}

const PuxCarousel: FunctionComponent<PuxCarouselType> = (props) => {
  const navigationSettings = {
    prevEl: `.${rootClass}-nav-left-${props.contentItemId}`,
    nextEl: `.${rootClass}-nav-right-${props.contentItemId}`,
  }

  return (
    <div className={rootClass}>
      {props.externalNavigation && (
        <div
          className={`${rootClass}-nav-left ${rootClass}-nav-left-${props.contentItemId}`}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
            <g transform='matrix(-1, 0, 0, 1, 19.997854, 0)'>
              <path d='M20,7a1,1,0,0,1-1,1H3.41l4.3,4.29a1,1,0,0,1,0,1.42A1,1,0,0,1,7,14a1,1,0,0,1-.71-.29l-6-6a1,1,0,0,1-.21-.33.92.92,0,0,1,0-.76,1,1,0,0,1,.21-.33l6-6A1,1,0,0,1,7.71,1.71L3.41,6H19A1,1,0,0,1,20,7Z' />
            </g>
          </svg>
        </div>
      )}
      <div className={`${rootClass}-content`}>
        <Swiper
          spaceBetween={50}
          slidesPerView={props.slidesPerView ? props.slidesPerView : undefined}
          pagination={{ clickable: true }}
          navigation={props.externalNavigation ? navigationSettings : true}
          loop={true}
          autoHeight={true}
          allowTouchMove={true}
          autoplay={{
            delay: 9000,
            disableOnInteraction: false,
          }}
          breakpoints={
            props.breakpoints ? props.breakpoints : DEFAULT_SWIPER_BREAKPOINTS
          }
        >
          {props.slides.map((slide, index) => (
            <SwiperSlide key={index}>{slide}</SwiperSlide>
          ))}
        </Swiper>
      </div>
      {props.externalNavigation && (
        <div
          className={`${rootClass}-nav-right ${rootClass}-nav-right-${props.contentItemId}`}
        >
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'>
            <g transform='matrix(-1, 0, 0, 1, 19.997854, 0)'>
              <path d='M20,7a1,1,0,0,1-1,1H3.41l4.3,4.29a1,1,0,0,1,0,1.42A1,1,0,0,1,7,14a1,1,0,0,1-.71-.29l-6-6a1,1,0,0,1-.21-.33.92.92,0,0,1,0-.76,1,1,0,0,1,.21-.33l6-6A1,1,0,0,1,7.71,1.71L3.41,6H19A1,1,0,0,1,20,7Z' />
            </g>
          </svg>
        </div>
      )}
    </div>
  )
}

export default PuxCarousel
